import { LanguageCode } from "~/apps/shared/constants/enums";
import { stringIncludes } from "~/apps/shared/utils/string-includes";
import isEmpty from "lodash/isEmpty";

import { HOTEL_SORT_OPTIONS } from "../../shared/constants";
import { HospitalityHotel, VisibleHotels } from "../models/hotel.model";

type Amenities =
  // | "air"
  | "breakfast"
  | "gym"
  | "parking"
  | "reception"
  | "refund"
  | "restaurant"
  | "wifi";

export function buildHotelData(
  hotelsSearch: any,
  travelToken: string | null = null,
) {
  const hotel = {
    hotel_end_date: hotelsSearch.endDate.format("YYYY-MM-DD"),
    hotel_initial_date: hotelsSearch.startDate.format("YYYY-MM-DD"),
    hotel_latitude: hotelsSearch.location.latitude,
    hotel_longitude: hotelsSearch.location.longitude,
    hotel_search: hotelsSearch.location.search,
    total_guests: hotelsSearch.totalGuests,
  };

  if (travelToken) {
    (hotel as typeof hotel & {
      travel_token?: string;
    }).travel_token = travelToken;
  }

  return hotel as typeof hotel & {
    travel_token?: string;
  };
}

function isHotelInPriceRange(
  hotel: HospitalityHotel,
  priceRange: [number, number],
) {
  const [min, max] = priceRange;
  const costPerNight = hotel.minPrice.value;
  return costPerNight <= max && costPerNight >= min;
}

function isHotelInDistanceRange(
  hotel: HospitalityHotel,
  distanceRange: [number, number],
) {
  const [min, max] = distanceRange;
  const { distance } = hotel;
  return distance <= max && distance >= min;
}

function hasHotelAnySpecifiedStars(
  hotel: HospitalityHotel,
  stars: {
    checked: boolean;
    value: number;
  }[],
) {
  if (stars.every((star) => !star.checked)) {
    return true;
  }

  return stars
    .filter((star) => star.checked)
    .some((star) => star.value === hotel.stars);
}

function hasHotelAllSpecifiedAmenities(
  hotel: HospitalityHotel,
  amenities: {
    checked: boolean;
    label: {
      [key in LanguageCode]: string;
    };
    value: Amenities;
  }[],
) {
  if (amenities.every((amenity) => !amenity.checked)) {
    return true;
  }

  return amenities
    .filter((amenity) => amenity.checked)
    .every((amenity) => hotel.popularAmenities[amenity.value]);
}

function sortResults(results: HospitalityHotel[], sortBy: string) {
  switch (sortBy) {
    case HOTEL_SORT_OPTIONS.PRICE:
      return results.sort(sortByPrice);
    case HOTEL_SORT_OPTIONS.DISTANCE:
      return distanceSort(results);
    case HOTEL_SORT_OPTIONS.RECOMMENDED:
      return results.sort(sortByRecommended);
    default:
      return results;
  }
}

function distanceSort(hotels: HospitalityHotel[]) {
  const hotelsWithoutDistance: HospitalityHotel[] = [];
  const hotelsWithDistance = hotels.reduce((acc: HospitalityHotel[], hotel) => {
    if (!hotel.distance) {
      hotelsWithoutDistance.push(hotel);
    } else {
      acc.push(hotel);
    }

    return acc;
  }, []);

  return hotelsWithDistance.sort(sortByDistance).concat(hotelsWithoutDistance);
}

function sortByPrice(itemA: HospitalityHotel, itemB: HospitalityHotel) {
  return itemA.minPrice.value - itemB.minPrice.value;
}

function sortByDistance(itemA: HospitalityHotel, itemB: HospitalityHotel) {
  return itemA.distance - itemB.distance;
}

function sortByRecommended(itemA: HospitalityHotel, itemB: HospitalityHotel) {
  const publicDataA = itemA.publicData;
  const publicDataB = itemB.publicData;

  if (!publicDataA) return 1;
  if (!publicDataB) return -1;

  if (publicDataA.bookingIndex != null && publicDataB.bookingIndex != null) {
    return publicDataA.bookingIndex - publicDataB.bookingIndex;
  }

  // Push items with null bookingIndex to the end
  if (publicDataA.bookingIndex != null) return -1;
  if (publicDataB.bookingIndex != null) return 1;

  // If both have null bookingIndex, check for scores
  const aHasScores = publicDataA.scores.general !== 0;
  const bHasScores = publicDataB.scores.general !== 0;

  if (aHasScores && !bHasScores) return -1;
  if (!aHasScores && bHasScores) return 1;

  return 0;
}

export function getVisibleHotels(
  hotels: HospitalityHotel[],
  selectedDistanceRange: [number, number],
  selectedPriceRange: [number, number],
  stars: {
    checked: boolean;
    value: number;
  }[],
  amenities: {
    checked: boolean;
    label: {
      [key in LanguageCode]: string;
    };
    value: Amenities;
  }[],
  onlyInPolicy: boolean,
  hotelSearch: string,
  sortedBy: string,
) {
  const sortedHotels = sortResults(hotels, sortedBy);

  const result = sortedHotels.reduce(
    (acc: VisibleHotels, hotel) => {
      const isInPriceRange = isHotelInPriceRange(hotel, selectedPriceRange);
      const isInDistanceRange = isHotelInDistanceRange(
        hotel,
        selectedDistanceRange,
      );
      const hasAnySpecifiedStars = hasHotelAnySpecifiedStars(hotel, stars);
      const hasAllSpecifiedAmenities = hasHotelAllSpecifiedAmenities(
        hotel,
        amenities,
      );

      const isAccordingToPolicy = onlyInPolicy ? !hotel.outOfPolicy : true;

      const containsSearch =
        (hotel.name && stringIncludes(hotel.name, hotelSearch)) ||
        (hotel.address && stringIncludes(hotel.address, hotelSearch));

      const areAllConditionsSatisfied =
        isInPriceRange &&
        isInDistanceRange &&
        hasAnySpecifiedStars &&
        hasAllSpecifiedAmenities &&
        containsSearch &&
        isAccordingToPolicy;

      if (areAllConditionsSatisfied && !isEmpty(hotel.favorites)) {
        acc.favouriteHotels.push(hotel);
      } else if (areAllConditionsSatisfied && isEmpty(hotel.favorites)) {
        acc.alternativeHotels.push(hotel);
      }

      return acc;
    },
    {
      favouriteHotels: [],
      alternativeHotels: [],
    },
  );

  return result;
}
