import { ApproverReplacementReason } from "~/apps/corporate/models/approver-replacement.model";
import {
  AuditAction,
  CarCategory,
  CurrencyCode,
  LanguageCode,
  VipStatus,
} from "~/apps/shared/constants/enums";

import { Option } from "../types";

export const ALERT_TYPES = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
} as const;

export const APPROVAL_REVIEW_MESSAGES = {
  NO_AVAILABILITY_DECLINE:
    "Sua viagem foi negada automáticamente devido a uma falta de disponibilidade das seguintes ofertas:",
} as const;

export const APPROVAL_STATUS = {
  APPROVED: 2,
  DECLINED: 3,
  DOES_NOT_NEED_APPROVAL: 100,
  PENDING_APPROVAL_2: 99,
  PENDING_APPROVAL: 1,
  UNITIATED: 0,
} as const;

export const APPROVAL_TYPES = {
  AREA: "AREA",
  COMPANY: "COMPANY",
  COST_CENTER: "COST-CENTER",
  PROJECT: "PROJECT",
  USER: "USER",
} as const;

export const APPROVER_REPLACEMENT_REASON_TRANSLATION = {
  [ApproverReplacementReason.OTHER]: "Outro",
  [ApproverReplacementReason.VACATION]: "Férias",
} as const;

export const APPROVER_REPLACEMENT_HISTORY_RECORD_ACTION_TRANSLATION = {
  [AuditAction.DELETE]: "Exclusão",
  [AuditAction.INACTIVATE]: "Inativação",
  [AuditAction.REACTIVATE]: "Reativação",
  [AuditAction.REGISTER]: "Registro",
  [AuditAction.UPDATE]: "Atualização",
  [AuditAction.SHUT_DOWN]: "Interrupção",
} as const;

export const BOOKING_FAILED_REASON = {
  CARD_BLOCKED: "CARD_BLOCKED",
  CARD_LIMIT: "CARD_LIMIT",
  COMPLETE_UNAVAILABILITY_SOLDOUT: "COMPLETE_UNAVAILABILITY_SOLDOUT",
  CUSTOMER_REQUEST: "CUSTOMER_REQUEST",
  HOTEL_UNAVAILABLE: "HOTEL_UNAVAILABLE",
  NEGOTIATION_UNAVAILABLE: "NEGOTIATION_UNAVAILABLE",
  OFFER_INTERNAL_CONDITION_SOLDOUT: "OFFER_INTERNAL_CONDITION_SOLDOUT",
  OTHER: "OTHER",
  PRICE_CHANGE: "PRICE_CHANGE",
  PROCESSING_ERROR: "PROCESSING_ERROR",
} as const;

const BOOKING_FAILED_REASON_TRANSLATED = {
  [BOOKING_FAILED_REASON.CARD_BLOCKED]:
    "Cartão nos foi indicado como bloqueado",
  [BOOKING_FAILED_REASON.CARD_LIMIT]: "Limite do cartão foi excedido",
  [BOOKING_FAILED_REASON.CUSTOMER_REQUEST]: "O solicitante desistiu da compra",
  [BOOKING_FAILED_REASON.NEGOTIATION_UNAVAILABLE]:
    "Tarifa negociada não ofertada para data selecionada",
  [BOOKING_FAILED_REASON.PRICE_CHANGE]: "Aumentou de preço.",
  [BOOKING_FAILED_REASON.PROCESSING_ERROR]: "Outro",
} as const;

export const BOOKING_FAILED_REASON_BUS_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "Não há mais disponibilidade no ônibus selecionado",
} as const;

export const BOOKING_FAILED_REASON_CAR_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "A categoria do carro não está mais disponível na agência selecionada",
} as const;

export const BOOKING_FAILED_REASON_FLIGHT_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "Não há mais disponibilidade no voo selecionado.",
  [BOOKING_FAILED_REASON.OFFER_INTERNAL_CONDITION_SOLDOUT]:
    "Indisponibilidade das condições no fornecedor, mas o voo está disponível.",
} as const;

export const BOOKING_FAILED_REASON_HOTEL_TRANSLATED = {
  ...BOOKING_FAILED_REASON_TRANSLATED,
  [BOOKING_FAILED_REASON.COMPLETE_UNAVAILABILITY_SOLDOUT]:
    "O quarto selecionado não está mais disponível",
  [BOOKING_FAILED_REASON.OFFER_INTERNAL_CONDITION_SOLDOUT]:
    "Indisponibilidade das condições no fornecedor, mas o quarto ainda está disponível",
  [BOOKING_FAILED_REASON.HOTEL_UNAVAILABLE]: "Hotel sem disponibilidade",
} as const;

export const BOUND_RANGE_TYPES = {
  arrival: "arrival",
  departure: "departure",
  duration: "duration",
} as const;

export const BOUND_TYPES = {
  inbound: "inbound",
  outbound: "outbound",
} as const;

export const BUS_STRETCH_TYPE = {
  DEPARTURE: "departure",
  RETURN: "return",
} as const;

export const CANCEL_TYPES = {
  ALTERED: "altered",
  CREDIT: "credit",
  NOSHOW_CREDIT: "noshow-credit",
  NOSHOW_REFUND: "noshow-refund",
  PARTIALLY_ALTERED: "partially-altered",
  REFUND: "refund",
} as const;

export const CANCEL_TYPES_TRANSLATION = {
  [CANCEL_TYPES.ALTERED]: "alteração",
  [CANCEL_TYPES.CREDIT]: "crédito",
  [CANCEL_TYPES.NOSHOW_CREDIT]: "noshow",
  [CANCEL_TYPES.NOSHOW_REFUND]: "noshow",
  [CANCEL_TYPES.PARTIALLY_ALTERED]: "alteração parcial",
  [CANCEL_TYPES.REFUND]: "reembolso",
} as const;

export const COMPANY_AREA_TYPES = {
  AREA: "area",
  BOARD: "board",
} as const;

export const CABINS = {
  business: "business",
  economy: "economy",
  first: "first",
  premiumEconomy: "premium_economy",
} as const;

export const CARD_BRANDS = {
  AMEX: "amex",
  B2B: "b2b",
  BANK3: "bank3",
  DINERS: "diners",
  ELO: "elo",
  MASTERCARD: "mastercard",
  VISA: "visa",
} as const;

export const CARD_SERVICE_TYPES = {
  BUS: "bus",
  CAR: "car",
  FEE: "fee",
  FLIGHT: "flight",
  GENERAL: "general",
  HOTEL: "hotel",
  TERRESTRIAL: "terrestrial",
} as const;

export const CAR_POLICY_CATEGORY_TRANSLATION = {
  [CarCategory.ADVANCED]: {
    [LanguageCode.EN_US]: "Advanced",
    [LanguageCode.PT_BR]: "Avançada",
  },
  [CarCategory.BASIC]: {
    [LanguageCode.EN_US]: "Basic",
    [LanguageCode.PT_BR]: "Básica",
  },
  [CarCategory.INTERMEDIATE]: {
    [LanguageCode.EN_US]: "Intermediate",
    [LanguageCode.PT_BR]: "Intermediária",
  },
  [CarCategory.SPECIAL]: {
    [LanguageCode.EN_US]: "Special",
    [LanguageCode.PT_BR]: "Especial",
  },
} as const;

export const CAR_TRANSMISSION_TYPES = {
  AUTOMATIC: "automatic",
  MANUAL: "manual",
} as const;

export const CURRENCY_OPTIONS: Option<CurrencyCode>[] = [
  {
    label: "$",
    value: CurrencyCode.USD,
  },
  {
    label: "R$",
    value: CurrencyCode.BRL,
  },
];

export const DEFAULT_COMPANY_AREA_NAME = "Áreas" as const;

export const DEFAULT_COMPANY_BOARD_NAME = "Diretorias" as const;

export const DOCUMENT_TYPES = {
  ID: "id",
  PASSPORT: "passport",
  RG: "rg",
} as const;

export const DOCUMENT_TYPES_TRANSCRIPTION = {
  [DOCUMENT_TYPES.ID]: {
    [LanguageCode.EN_US]: "CPF",
    [LanguageCode.PT_BR]: "CPF",
  },
  [DOCUMENT_TYPES.PASSPORT]: {
    [LanguageCode.EN_US]: "Passport",
    [LanguageCode.PT_BR]: "Passaporte",
  },
  [DOCUMENT_TYPES.RG]: {
    [LanguageCode.EN_US]: "ID",
    [LanguageCode.PT_BR]: "RG",
  },
} as const;

export const DOCUMENT_TYPES_TRANSLATION = {
  [DOCUMENT_TYPES.ID]: "CPF",
  [DOCUMENT_TYPES.PASSPORT]: "Passaporte",
  [DOCUMENT_TYPES.RG]: "RG",
} as const;

export const FAVORITE_TYPES = {
  COMPANY: "c",
  GROUP: "g",
  USER: "u",
} as const;

export const FLIGHT_CABIN_CLASS_TRANSLATOR = {
  business: {
    [LanguageCode.EN_US]: "Business",
    [LanguageCode.PT_BR]: "Executiva",
  },
  economy: {
    [LanguageCode.EN_US]: "Economy",
    [LanguageCode.PT_BR]: "Econômica",
  },
  first: {
    [LanguageCode.EN_US]: "First Class",
    [LanguageCode.PT_BR]: "Primeira Classe",
  },
  premium_economy: {
    [LanguageCode.EN_US]: "Premium Economy",
    [LanguageCode.PT_BR]: "Econômica Premium",
  },
} as const;

export const FLIGHT_ORDER_TYPES = {
  CHEAPEST: "cheapest",
  EARLIEST_INBOUND_ARRIVAL: "earliest_inbound_arrival",
  EARLIEST_INBOUND_DEPARTURE: "earliest_inbound_departure",
  EARLIEST_OUTBOUND_ARRIVAL: "earliest_outbound_arrival",
  EARLIEST_OUTBOUND_DEPARTURE: "earliest_outbound_departure",
  FASTEST: "fastest",
  RECOMMENDED: "recommended",
} as const;

export const FLIGHT_ORDER_TYPES_TRANSLATOR = {
  [FLIGHT_ORDER_TYPES.CHEAPEST]: {
    [LanguageCode.EN_US]: "Cheapest",
    [LanguageCode.PT_BR]: "Mais barato",
  },
  [FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_ARRIVAL]: {
    [LanguageCode.EN_US]: "Earliest arrival - Return",
    [LanguageCode.PT_BR]: "Chegada mais cedo - Volta",
  },
  [FLIGHT_ORDER_TYPES.EARLIEST_INBOUND_DEPARTURE]: {
    [LanguageCode.EN_US]: "Earliest departure - Return",
    [LanguageCode.PT_BR]: "Partida mais cedo - Volta",
  },
  [FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_ARRIVAL]: {
    [LanguageCode.EN_US]: "Earliest arrival - Departure",
    [LanguageCode.PT_BR]: "Chegada mais cedo - Ida",
  },
  [FLIGHT_ORDER_TYPES.EARLIEST_OUTBOUND_DEPARTURE]: {
    [LanguageCode.EN_US]: "Earliest departure - Departure",
    [LanguageCode.PT_BR]: "Partida mais cedo - Ida",
  },
  [FLIGHT_ORDER_TYPES.FASTEST]: {
    [LanguageCode.EN_US]: "Fastest",
    [LanguageCode.PT_BR]: "Mais rápido",
  },
  [FLIGHT_ORDER_TYPES.RECOMMENDED]: {
    [LanguageCode.EN_US]: "Recommended",
    [LanguageCode.PT_BR]: "Recomendado",
  },
} as const;

export const HOTEL_SORT_OPTIONS = {
  DISTANCE: "DISTANCE",
  PRICE: "PRICE",
  RECOMMENDED: "RECOMMENDED",
} as const;

export const LANGUAGE_OPTIONS: Option<LanguageCode>[] = [
  {
    label: "English",
    value: LanguageCode.EN_US,
  },
  {
    label: "Português",
    value: LanguageCode.PT_BR,
  },
];

export const LOCATION_AUTOCOMPLETE_SHIMS: Record<
  "brazilianCarRentals" | "brazilianCities" | "worldwideCities",
  LocationType[]
> = {
  brazilianCarRentals: [
    "sublocality",
    "street_address",
    "airport",
    "car_rental",
    "point_of_interest",
  ],
  brazilianCities: ["administrative_area_level_2"],
  worldwideCities: ["(cities)"],
};

export const LOYALTY_PROGRAMS = {
  latam_pass: "Latam Pass",
  smiles: "Smiles",
  tudo_azul: "Tudo Azul",
} as const;

export const MONTH_MAP: Record<number, string> = {
  1: "Jan",
  2: "Fev",
  3: "Mar",
  4: "Abr",
  5: "Mai",
  6: "Jun",
  7: "Jul",
  8: "Ago",
  9: "Set",
  10: "Out",
  11: "Nov",
  12: "Dez",
} as const;

export const NATIONAL_TYPES = {
  INTERNATIONAL: "international",
  NATIONAL: "national",
} as const;

export const PAYMENT_TYPES = {
  BILLED_SUPPLIER: "supplier_billed",
  BILLED: "billed",
  CREDIT_CARD: "credit_card",
  DEBT_CARD: "debt_card",
  MONEY: "money",
} as const;

export const PROJECT_ENABLING_STATE = {
  OFF: "off",
  OPTIONAL: "optional",
  REQUIRED: "required",
} as const;

export const RESERVE_STATUS = {
  FAILED: "FAILED",
  FINAL_PENDING: "FINAL_PENDING",
  PENDING: "PENDING",
  RESERVED: "RESERVED",
  UNITIATED: "UNITIATED",
} as const;

export const SEND_VOUCHER_EMAIL_SUCCESS_MESSAGE =
  "Requisição de email feita com sucesso. Em instantes você receberá um email com vouchers.";

export const SERVICE_TYPES = {
  bus: "bus",
  car: "car",
  flight: "flight",
  hotel: "hotel",
  insurance: "insurance",
  none: "",
} as const;

export const SEXES = [
  {
    label: {
      [LanguageCode.EN_US]: "Male",
      [LanguageCode.PT_BR]: "Masculino",
    },
    value: "M",
  },
  {
    label: {
      [LanguageCode.EN_US]: "Female",
      [LanguageCode.PT_BR]: "Feminino",
    },
    value: "F",
  },
] as const;

export const SUPPLIERS = {
  CLICKBUS: "CLICKBUS",
  ITERPEC: "ITERPEC",
  LOCALIZA: "LOCALIZA",
  MOVIDA: "MOVIDA",
  SKYTEAM: "SKYTEAM",
  TECH_TRAVEL: "TECH_TRAVEL",
} as const;

export const TARGET_TYPES = {
  COMPANY: "c",
  GROUP: "g",
  USER: "u",
} as const;

export const TRAVEL_NAME_PLACEHOLDERS = {
  [LanguageCode.EN_US]: [
    "Meeting with client ABC",
    "Commercial training in RJ",
    "Visit to client XYZ in SP",
    "Urgent technical visit to client XPTO",
  ],
  [LanguageCode.PT_BR]: [
    "Reunião com o cliente ABC",
    "Treinamento comercial no RJ",
    "Visita ao cliente XYZ em SP",
    "Visita técnica urgente ao cliente XPTO",
  ],
} as const;

export const TRAVELS_PAGE_REDIRECT_MESSAGES = {
  APPROVAL_REQUEST_CANCELED: "A solicitação de aprovação foi cancelada",
  APPROVAL_REQUEST_NOT_PENDING:
    "A viagem selecionada já teve sua revisão realizada.",
  CANNOT_ACCESS_PAYMENT:
    "Você não possui permissão para processar o pagamento da viagem.",
  TRAVEL_STATUS_PAYMENT_NOT_ALLOWED:
    "Reservas não podem ser realizadas no status atual da viagem.",
  USER_NOT_APPROVER: "Você não possui permissão para revisar esta viagem.",
  USER_NOT_STAGE_APPROVER:
    "Você não possui permissão para revisar esta etapa. É possível que sua etapa já tenha sido revisada por outro aprovador",
} as const;

export const USER_ROLES = {
  admin: "ROLE_ADMIN",
  customized: "CUSTOMIZED",
  manager: "ROLE_MANAGER",
  self_traveler: "ROLE_SELF_TRAVELER",
  super: "ROLE_SUPERADMIN",
  traveler: "ROLE_TRAVELER",
  user: "ROLE_USER",
} as const;

export const VIP_STATUS_TRANSLTATED: Record<VipStatus, string> = {
  [VipStatus.COMPANY_VIP]: "Alta (na hierarquia da empresa)",
  [VipStatus.CROSS_COMPANY_VIP]: "VIP",
  [VipStatus.REGULAR]: "Normal",
} as const;
