import { USER_ROLES } from "~/apps/shared/constants";
import {
  Capabilities,
  CapabilitiesLiterals,
  CurrencyCode,
  LanguageCode,
  UserBookingPhoneConfig,
  VipStatus,
} from "~/apps/shared/constants/enums";

import { ApprovalBypass } from "../dtos/user.dto";

export type User = {
  active?: boolean;
  agencyToken?: string;
  approvalBypass?: ApprovalBypass;
  billingProfileToken?: string | null;
  bookingPhoneConfig?: UserBookingPhoneConfig;
  bossToken?: string | null;
  capabilities?: Partial<Capabilities>;
  clientName?: string;
  clientToken?: string;
  createdAt?: string;
  email: string;
  firstName: string;
  fullName?: string;
  guest?: boolean | null;
  hasAcceptedTerms?: boolean;
  hidden?: boolean;
  lastLogin?: string | null;
  lastName: string;
  phone?: string | null;
  plan?: string;
  role?: string;
  sendSms?: boolean | null;
  userPreferences?: UserPreferences | null;
  userToken: string;
  vipStatus?: VipStatus;
};

export type UserPreferences = {
  currency: CurrencyCode;
  language: LanguageCode;
};

export type UserFromAccessToken = {
  agencyToken?: string;
  capabilities?: Partial<Capabilities>;
  clientName?: string;
  clientToken?: string;
  guest?: boolean | null;
  hidden?: boolean;
  plan?: string;
  role?: string;
  sendSms?: boolean | null;
  userPreferences: {
    currency: CurrencyCode;
    language: LanguageCode;
  } | null;
  userToken: string;
  vipStatus?: VipStatus;
};

export class UserModel {
  constructor(private user: User) {}

  public canSearch() {
    return this.hasSearchCapability() || this.hasSelfSearchCapability();
  }

  public getAgencyToken() {
    const agencyToken = this.user.agencyToken;

    if (!agencyToken) {
      return null;
    }

    return agencyToken;
  }

  public getBillingProfileToken() {
    return this.user.billingProfileToken || null;
  }

  public getClientName() {
    return this.user.clientName;
  }

  public getClientToken() {
    return this.user.clientToken;
  }

  public getCurrencyCode() {
    const { userPreferences } = this.user;

    if (!userPreferences) {
      return null;
    }

    return userPreferences.currency;
  }

  public getEmail() {
    return this.user.email;
  }

  public getFirstName() {
    return this.user.firstName;
  }

  public getFullName() {
    return this.user.fullName || `${this.user.firstName} ${this.user.lastName}`;
  }

  public getLastName() {
    return this.user.lastName;
  }

  public getLanguageCode() {
    const { userPreferences } = this.user;

    if (!userPreferences) {
      return null;
    }

    return userPreferences.language;
  }

  public getPhone() {
    return this.user.phone;
  }

  public getPlan() {
    return this.user.plan;
  }

  public getRole() {
    return this.user.role;
  }

  public getSendSms() {
    return this.user.sendSms;
  }

  public getUserPreferences() {
    return this.user.userPreferences;
  }

  public getUserToken() {
    return this.user.userToken;
  }

  public hasApprovalsCapability() {
    return this.hasCapability("approvals");
  }

  private hasApprovalAnyTimeForOthers() {
    return this.hasCapability("approvalAnyTimeForOthers");
  }

  public hasCapability(capability: CapabilitiesLiterals) {
    const capabilities = this.user.capabilities;

    return capabilities && capability in capabilities
      ? capabilities[capability]!
      : false;
  }

  public hasConfigurationsCapability() {
    return this.hasCapability("configurations");
  }

  public hasLoggedIn() {
    return !!this.user.lastLogin;
  }

  public hasSearchCapability() {
    return this.hasCapability("search");
  }

  public hasSelfSearchCapability() {
    return this.hasCapability("selfSearch");
  }

  public isActive() {
    return !!this.user.active;
  }

  public isAdmin() {
    return this.getRole() === USER_ROLES.admin;
  }

  public isGuest() {
    return !!this.user.guest;
  }

  public isHidden() {
    return !!this.user.hidden;
  }

  public isMasterApprover() {
    return this.hasApprovalAnyTimeForOthers();
  }

  public isSuperAdmin() {
    return this.getRole() === USER_ROLES.super;
  }

  public toObject() {
    return this.user;
  }

  public upsertUser(user: Partial<User>) {
    this.user = {
      ...this.user,
      ...user,
    };

    return this;
  }

  public getUserVipStatus() {
    const vipStatus = this.user.vipStatus;

    if (!vipStatus) {
      return null;
    }

    return vipStatus;
  }

  public approvalBypassIsEnabled() {
    return this.user.approvalBypass === ApprovalBypass.ENABLED;
  }
}

export class UserModelFactory {
  public static create(user: User) {
    return new UserModel(user);
  }
}

export type UserSearch = {
  clientToken: string;
  document: string;
  email: string;
  firstName: string;
  fullName: string;
  label: string;
  lastName: string;
  userToken: string;
};

export type UserSimplified = {
  active: boolean;
  billingProfileToken: string | null;
  email: string;
  firstName: string;
  fullName: string;
  guest?: boolean | null;
  lastName: string;
  role?: string;
  userToken: string;
};
